// Generated by Framer (23a204f)

import { addFonts, cx, CycleVariantState, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion } from "framer-motion";
import * as React from "react";

const cycleOrder = ["ALGchWC4K"];

const variantClassNames = {ALGchWC4K: "framer-v-1d6qj4m"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const humanReadableVariantMap = {};

const transitions = {default: {damping: 60, delay: 0, duration: 0.3, ease: [0.44, 0, 0.56, 1], mass: 1, stiffness: 500, type: "spring"}};

export interface Props extends React.HTMLAttributes<HTMLDivElement> { layoutId?: string | null; width?: number; height?: number; layoutDependency?: string | number; variant?: keyof typeof humanReadableVariantMap; }

const Component = React.forwardRef<HTMLDivElement, Props>(function({ id, style, className, width, height, layoutId, variant: outerVariant = "ALGchWC4K", ...restProps }, ref) {

const outerVariantId = humanReadableVariantMap[outerVariant];

const variant = outerVariantId || outerVariant;

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "ALGchWC4K", transitions, variant, variantClassNames})
const layoutDependency = variants.join("-") + restProps.layoutDependency;

const defaultLayoutId = React.useId()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-0Gm1T", classNames)} style={{display: "contents"}}>
<motion.div {...restProps} className={cx("framer-1d6qj4m", className)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"ALGchWC4K"} ref={ref} style={{backgroundColor: "rgb(255, 255, 255)", ...style}} transition={transition}/>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-0Gm1T [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-0Gm1T .framer-qgt0sh { display: block; }", ".framer-0Gm1T .framer-1d6qj4m { height: 200px; position: relative; width: 200px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 200
 * @framerIntrinsicWidth 200
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]}}}
 */
const Framertmc3N8vGy: React.ComponentType<Props> = withCSS(Component, css, "framer-0Gm1T") as typeof Component;
export default Framertmc3N8vGy;

Framertmc3N8vGy.displayName = "Control ROI";

Framertmc3N8vGy.defaultProps = {height: 200, width: 200};

addFonts(Framertmc3N8vGy, [])